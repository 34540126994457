import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // Importar el CSS para highlight.js
import './MarkdownReader.css';
import { useTranslation } from 'react-i18next';

const MarkdownReader = ({ markdownUrl }) => {
    const { t } = useTranslation();
    const [markdown, setMarkdown] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [mod, setMod] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');
    const [modalImageTitle, setModalImageTitle] = useState('');
    const fullPath = process.env.PUBLIC_URL + '/' + markdownUrl;

    const extractFrontMatter = (text) => {
        const frontMatterMatcher = /^---\s*[\r\n]([\s\S]+?)\s*---/;
        const titleMatcher = /^title:\s*(.+)$/m;
        const dateMatcher = /^date:\s*(.+)$/m;
        const modMatcher = /^modification:\s*(.+)$/m;
        const frontMatterMatch = text.match(frontMatterMatcher);
        let contentWithoutFrontMatter = text;
        let title = '';
        let date = '';
        let mod = '';

        if (frontMatterMatch) {
            const frontMatterContent = frontMatterMatch[1];
            const titleMatch = frontMatterContent.match(titleMatcher);
            const dateMatch = frontMatterContent.match(dateMatcher);
            const modMatch = frontMatterContent.match(modMatcher);
            contentWithoutFrontMatter = text.slice(frontMatterMatch[0].length);

            if (titleMatch) {
                title = titleMatch[1].trim();
            }

            if (dateMatch) {
                date = dateMatch[1].trim();
            }

            if (modMatch) {
                mod = modMatch[1].trim();
            }
        }

        return { contentWithoutFrontMatter, title, date, mod };
    };

    window.copyCodeToClipboard = function (button) {
        const codeBlock = button.parentNode.parentNode.querySelector('code');
        const lines = codeBlock.querySelectorAll('.code-line');
        const codeText = Array.from(lines).map(line => line.textContent).join('\n');

        navigator.clipboard.writeText(codeText).then(() => {
            button.textContent = t("copied_code");
            setTimeout(() => {
                button.textContent = t("copy_code");
            }, 2000);
        });
    };

    useEffect(() => {
        fetch(fullPath)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.text();
            })
            .then(text => {
                const { contentWithoutFrontMatter, title, date, mod } = extractFrontMatter(text);
                setTitle(title);
                setDate(date);
                setMod(mod);

                marked.setOptions({
                    highlight: function (code, lang) {
                        if (lang && hljs.getLanguage(lang)) {
                            return hljs.highlight(code, { language: lang }).value;
                        }
                        return hljs.highlightAuto(code).value;
                    },
                    langPrefix: 'hljs '
                });

                const htmlContent = marked(contentWithoutFrontMatter);
                setMarkdown(htmlContent);
            })
            .catch(error => {
                console.error('Error fetching markdown:', error);
                setMarkdown('<p>Error loading post!</p>');
            });
    }, [fullPath]);

    useEffect(() => {
        if (markdown) {
            const markdownElement = document.querySelector('.markdown');
            if (markdownElement) {
                hljs.highlightAll();
                const highlightedHtml = markdownElement.innerHTML;
                const htmlWithLineNumbers = addLineNumbers(highlightedHtml);
                markdownElement.innerHTML = htmlWithLineNumbers;
            }

            const images = document.querySelectorAll('.markdown img');
            images.forEach(img => {
                img.addEventListener('click', () => {
                    setModalImageSrc(img.src);
                    setModalImageTitle(img.title);
                    setIsImageModalOpen(true);
                });

                const spinner = document.createElement('div');
                spinner.className = 'loader';
                img.style.display = 'none';
                img.parentNode.insertBefore(spinner, img);

                img.onload = () => {
                    img.style.display = '';
                    spinner.remove();
                };
            });
        }
    }, [markdown]);

    const addLineNumbers = (html) => {
        const codeBlockRegex = /<pre><code([^>]*)>([\s\S]*?)<\/code><\/pre>/g;
        const matches = [...html.matchAll(codeBlockRegex)];

        matches.forEach((match) => {
            const [fullMatch, attrs, code] = match;
            const lines = code.split('\n');

            if (lines[lines.length - 1] === '') {
                lines.pop();
            }

            // Extract the language from the class attribute
            const languageMatch = attrs.match(/class="([^"]*language-([^"\s]+)[^"]*)"/);
            const language = languageMatch ? languageMatch[2] : 'plaintext';

            const newCODE = lines.map((line, idx) =>
                `<span class="line-number">${idx + 1}</span><span class="code-line">${line}</span>\n`
            ).join('');

            const newCodeBlock = `<pre><div><span class='code-lang-name'>${language}</span><span class='copy-btn' onclick="copyCodeToClipboard(this)">${t("copy_code")}</span></div><hr><code${attrs}>${newCODE}</code></pre>`;

            html = html.replace(fullMatch, newCodeBlock);
        });

        return html;
    };

    const closeModal = () => {
        setIsImageModalOpen(false);
        setModalImageSrc('');
    };

    return (
        <div className={`container container-markdown`}>
            <div className='post-header'>
                <h1>{title.replace(/'/g, '')}</h1>
                <p style={{ textAlign: 'end' }}>{date ? `Date of publication: ${date}` : ''}<br /> {mod ? `Date of modification: ${mod}` : ''}</p>
            </div>
            <article className='markdown' dangerouslySetInnerHTML={{ __html: markdown }} />
            {isImageModalOpen && (
                <div className="image-modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-btn" onClick={closeModal}>&times;</span>
                        <img src={modalImageSrc} alt="Full screen" />
                        <center>
                            <span style={{color:'white'}}>{modalImageTitle}</span>
                        </center>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarkdownReader;
